import React from 'react';
import { DefaultButton, Stack, SearchBox, IContextualMenuProps, FontIcon, Label, DatePicker, DayOfWeek } from '@fluentui/react';
import { Navigator } from "../Shared/Navigator"

interface IMapOverlayState {
}

interface IMapOverlayProps {
  showOwn: boolean,
  showVo: boolean,
  date?: Date,
  onShowOwnChanged: (show: boolean) => void,
  onShowVoChanged: (show: boolean) => void,
  onDateChanged: (date?: Date | null) => void,
  textMessage?: string
}

export class MapOverlay extends React.Component<IMapOverlayProps, IMapOverlayState> {
  private getMenuProps(): IContextualMenuProps {
    return {
      items: [
        {
          key: 'showAll',
          text: 'Zeige nur Meine Kurse',
          iconProps: { iconName: this.props.showOwn ? 'CheckboxComposite' : 'Checkbox' },
          onClick: () => { this.props.onShowOwnChanged(!this.props.showOwn) }
        },
        {
          key: 'showVo',
          text: 'Zeige nur Vorlesungen',
          iconProps: { iconName: this.props.showVo ? 'CheckboxComposite' : 'Checkbox' },
          onClick: () => { this.props.onShowVoChanged(!this.props.showVo) }
        },
        {
          key: 'config',
          text: 'Kursliste anzeigen',
          iconProps: { iconName: 'AllApps' },
          onClick: () => Navigator.getInstance().navigate("/courses"),
        },
      ],
    }
  }

  render() {
    return <>
      <div style={{
        position: 'absolute',
        zIndex: 1000,
        right: '0px',
        border: '1px solid grey',
        backgroundColor: 'white',
        margin: '10px',
        padding: '5px',
      }}>
        <Stack tokens={{ childrenGap: 5 }} >
          <DefaultButton split menuProps={this.getMenuProps()} iconProps={{ iconName: "AllApps" }} title="Kurse" onClick={() => Navigator.getInstance().navigate("/courses")}>Kurse</DefaultButton>
          <DatePicker
            firstDayOfWeek={DayOfWeek.Monday}
            placeholder={new Date().toLocaleDateString()}
            value={this.props.date}
            formatDate={(date?: Date) => date?.toLocaleDateString() ?? "z"}
            onSelectDate={(date: Date | null | undefined) => { this.props.onDateChanged(date) }}
          />
          {this.props.textMessage &&
            <div style={{
              border: "solid 1px rgb(138, 136, 134)",
              borderRadius: 2,
              padding: 4,
              maxWidth: 121
            }}>
              <span style={{ fontSize: 14 }}> <FontIcon iconName="Warning" style={{ marginRight: 4, fontSize: 14 }} />{this.props.textMessage}</span>
            </div>
          }
        </Stack>
      </div>
    </>;
  }
}