import React from 'react';
import { Routes, Route } from "react-router-dom";
import { MapView } from "./Views/MapView"
import { CourseView } from "./Views/CourseView"
import { CourseSelectView } from "./Views/CourseSelectView"
import { RandomSelectView } from "./Views/RandomSelectView"
import { Layout } from './Shared/Layout';
import { CourseStorage } from './Data/CourseStorage';
import { Navigator } from "./Shared/Navigator"
import { SetupView } from './Views/SetupView';
import axios from 'axios';

export default class App extends React.Component<{}, {}> {
  constructor(props: any) {
    super(props);

    var courseStorage = new CourseStorage();

    axios.get('current.txt', {
      decompress: true,
      responseType: 'text'
    }).then(data => {

      if (courseStorage.isDataLoaded(data.data) === false) {
        setTimeout(function () { Navigator.getInstance().navigate("/init"); }, 300);
      }
    })



  }

  render() {
    return <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/map" element={<MapView />} />
          <Route path="/courses" element={<CourseView />} />
          <Route path="/courseSelect" element={<CourseSelectView />} />
          <Route path="/randomSelect" element={<RandomSelectView />} />
          <Route path="/init" element={<SetupView />} />
          <Route index element={<MapView />} />
        </Route>
      </Routes>
    </>;
  }
}
